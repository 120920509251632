.software-cost-calculator {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    font-family: sans-serif;
  }
  
  h1 {
    margin-top: 0;
  }
  
  .feature-selection, .project-size, .total-cost {
    margin: 20px;
    width: 50%;
    max-width: 500px;
  }
  
  .project-size input[type='range'] {
    width: 100%;
  }
  
  .checkbox input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  .checkbox label {
    display: inline-block;
    position: relative;
    padding-left: 26px;
    margin-bottom: 10px;
    cursor: pointer;
    font-size: 16px;
    user-select: none;
  }
  
  .checkbox label:before {
    content: "";
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #cccccc;
    background-color: #ffffff;
    border-radius: 4px;
  }
  
  .checkbox input:checked + label:before {
    content: "\f00c";
    display: inline-block;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 14px;
    color: #ffffff;
    background-color: #007bff;
    border-color: #007bff;
    border-radius: 4px;
    text-align: center;
    line-height: 18px;
  }
  
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  