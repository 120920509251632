.container {
    padding: 20px;
  }
  
  .header {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 10px;
    padding-left: 30px;
  }
  
  .subheader {
    font-size: 1.2rem;
    color: #777;
    margin-bottom: 20px;
    padding-left: 30px;

  }
  
  .gridForTools {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  