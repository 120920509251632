.project-planner-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 40px #0061fd1a;
  }
  
  h1 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  p {
    font-size: 1.1rem;
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
  }
  
  select, input {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-top: 5px;
  }
  
  input.error {
    border-color: red;
  }
  
  .error {
    color: red;
    font-size: 0.9rem;
    margin-top: 5px;
  }
  
  .calculate-btn {
    padding: 10px 20px;
    font-size: 1rem;
    background-color: #164C79;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .calculate-btn:hover {
    background-color: #487094;
  }
  
  .timeline-result {
    margin-top: 20px;
    background-color: #e9ecef;
    padding: 20px;
    border-radius: 5px;
  }
  
  .cta-btn {
    margin-top: 15px;
    padding: 10px 20px;
    background-color: #164C79;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .cta-btn:hover {
    background-color: #487094;
  }
  