
.card {
    width: 48%;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    color: #fff;
    box-shadow: 0 0 40px #0061fd1a;
    cursor: pointer;
  }
  
  .icon {
    width: 80px;
    height: 80px;
    margin-bottom: 10px;
  }
  
  .card-title {
    font-size: 24px;
    font-weight: bold;
  }
  
  .card-description {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;
  }
  
  .use-tool-btn {
    font-size: 14px;
    color: #ffffff;
    background: none;
    border: none;
    cursor: pointer;
  }
  